<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-row v-auth-acl="'gh-proy-cre_proy-insert'">
      <v-col cols="12" sm="12">
        <BaseCard classAux="pa-0">
          <ProyectoFormulario @open="open" @close="close"></ProyectoFormulario>
        </BaseCard>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Proyecto',
  components: {
    ProyectoFormulario: () => import('../components/ProyectoFormulario')
  },
  data: () => ({
    page: {
      title: 'PROYECTO'
    },
    breadcrumbs: [
      {
        text: 'Proyecto',
        disabled: true
      },
      {
        text: 'Crear Ficha Técnica del Proyecto',
        disabled: false
      }
    ]
  }),
  methods: {
    open() {},
    close() {}
  }
};
</script>
