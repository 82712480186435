class PresupuestoService {
  constructor() {}
  resources = () => ({
    descargarpresupuesto: {
      uri: `/v1/presupuesto/descargarexcel`,
      method: ['get']
    },
    descargar: {
      uri: `/v1/presupuesto/descargararchivos`,
      method: ['get']
    },
    archivos: {
      uri: `/v1/presupuesto/archivos`,
      method: ['get']
    }
  });
}

export default PresupuestoService;
