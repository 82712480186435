<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs" height="55px">
      <template v-slot:item-right>
        <div class="d-flex pt-2" style="height: 40px; font-weight: 500;">
          <v-select
            dense
            hide-details
            class="mr-4"
            v-model="anio"
            :items="listAnios"
            @change="cambioAnio"
            no-data-text="No se encontró información"
            item-text="text"
            item-value="value"
            label="Año"
          ></v-select>
          <v-select
            dense
            hide-details
            v-model="semana"
            :items="semanas"
            no-data-text="No se encontró información"
            item-text="text"
            item-value="value"
            label="Semana"
          >
          </v-select>
        </div>
      </template>
    </BaseBreadcrumb>
    <v-row v-auth-acl="'gh-okr-dash-read'">
      <v-col cols="12" sm="12" lg="12">
        <v-card class="mb-7">
          <v-card-title class="pb-3 pt-4">
            <h3>CUMPLIMIENTO</h3>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="py-0 px-3">
            <v-row class="my-2 pa-0 d-flex pt-4 pb-0">
              <v-col cols="12" sm="12" lg="6" class="py-0">
                <CumplimientoPL :datos="datos"></CumplimientoPL>
              </v-col>
              <v-col cols="12" sm="12" lg="6" class="py-0">
                <CantidadRNR :datos="datos"></CantidadRNR>
              </v-col>
              <v-col cols="12" sm="12" lg="12" class="py-0">
                <CantidadRNRXArea :datos="datos"></CantidadRNRXArea>
              </v-col>
              <v-col cols="12" sm="12" lg="12" class="py-0">
                <CumplimientoPLXArea :datos="datos"></CumplimientoPLXArea>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" lg="12">
        <v-card class="mb-7">
          <v-card-title class="pb-3 pt-4">
            <h3>ENTREGA</h3>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="py-0 px-3">
            <v-row class="my-2 pa-0 d-flex pt-4 pb-0">
              <v-col cols="12" sm="12" lg="12" class="py-0">
                <EntregaOKR :datos="datos"></EntregaOKR>
              </v-col>
              <v-col cols="12" sm="12" lg="12" class="py-0">
                <EntregaXArea :datos="datos"></EntregaXArea>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <div v-if="showLoading" class="progress-background">
      <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
    </div>
  </v-container>
</template>

<script>
import DashboardService from '@/giplan/okr/dashboard/services/DashboardService';
import moment from 'moment';
import { decryptAES } from '@/components4x/utils/utils4x';
export default {
  name: 'Dashboard',
  data: () => ({
    semana: '',
    semanas: [],
    datos: {},
    showLoading: false,
    dashboardService: null,
    page: {
      title: 'OKR'
    },
    breadcrumbs: [
      {
        text: 'OKR',
        disabled: true
      },
      {
        text: 'Dashboard',
        disabled: false
      }
    ],
    anio: new Date().getFullYear().toString(),
    listAnios: []
  }),
  watch: {
    async semana() {
      await this.initialize();
    }
  },
  components: {
    CumplimientoPLXArea: () => import('../components/CumplimientoPLXArea'),
    CantidadRNRXArea: () => import('../components/CantidadRNRXArea'),
    EntregaXArea: () => import('../components/EntregaXArea'),
    EntregaOKR: () => import('../components/EntregaOKR'),
    CantidadRNR: () => import('../components/CantidadRNR'),
    CumplimientoPL: () => import('../components/CumplimientoPL')
  },
  methods: {
    async initialize() {
      this.datos = {};
      this.showLoading = true;
      const emp = decryptAES(localStorage.getItem('emp'));
      const res = await this.dashboardService.get().execResource('dashboard', {
        idempresa: emp,
        semana: this.semana,
        anio: this.anio
      });
      this.datos = res;
      this.showLoading = false;
    },
    async semanasItems() {
      this.showLoading = true;
      this.semana = [];
      const res = await this.dashboardService.get().execResource('fecha', {
        fecha: moment().year() + '-12-31'
      });
      for (let i = 1; i <= res; i++) {
        this.semanas.push(i);
      }
      this.semana = await this.dashboardService.get().execResource('fecha', {
        fecha: this.anio == moment().year() ? moment().format('YYYY-MM-DD') : moment().year() + '-12-31'
      });
      this.showLoading = false;
    },
    async cargarAnios() {
      this.listAnios = [];
      this.showLoading = true;
      const emp = decryptAES(localStorage.getItem('emp'));
      const res = await this.dashboardService.get().execResource('cargarAnios', {
        idempresa: emp
      });
      this.listAnios = res.map((el) => {
        return { value: el.anio, text: el.anio };
      });
      this.showLoading = false;
    },
    async cambioAnio() {
      await this.semanasItems();
      await this.initialize();
    }
  },
  async created() {
    this.dashboardService = this.$httpService(new DashboardService(), this);
    await this.cargarAnios();
    await this.semanasItems();
    await this.initialize();
  }
};
</script>
