<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-row v-auth-acl="'gh-okr-cre_okr-insert'">
      <v-col cols="12" sm="12">
        <BaseCard>
          <MetodologiaOKRFormulario @open="open" @close="close"></MetodologiaOKRFormulario>
        </BaseCard>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'MetodologiaOKR',
  components: {
    MetodologiaOKRFormulario: () => import('../components/MetodologiaOKRFormulario')
  },
  data: () => ({
    page: {
      title: 'OKR'
    },
    breadcrumbs: [
      {
        text: 'OKR',
        disabled: true
      },
      {
        text: 'Crear OKR',
        disabled: false
      }
    ]
  }),
  methods: {
    open() {},
    close() {}
  }
};
</script>
