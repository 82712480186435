<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-row v-auth-acl="'gh-pres-repre-insert'">
      <v-col cols="12" sm="12">
        <BaseCard>
          <PresupuestoFormulario></PresupuestoFormulario>
        </BaseCard>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Registro',

  data: () => ({
    page: {
      title: 'Presupuesto'
    },
    breadcrumbs: [
      {
        text: 'Presupuesto',
        disabled: true
      },
      {
        text: 'Registro de Presupuesto',
        disabled: false
      }
    ]
  }),
  components: {
    PresupuestoFormulario: () => import('../components/PresupuestoFormulario')
  }
};
</script>
