class DashboardService {
  constructor() {}
  resources = () => ({
    dashboard: {
      uri: `/v1/okr/dasboard`,
      method: ['get']
    },
    fecha: {
      uri: `/v1/semana`,
      method: ['get']
    },
    cargarAnios: {
      uri: `/v1/okr/listanios`,
      method: ['get']
    }
  });
}

export default DashboardService;
